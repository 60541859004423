@import "./../../variables";

.vessel-component {
  height: calc(100vh - 259px);
  overflow: auto;
  scrollbar-width: none;
  .form-field-cover {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 20px;
  }
  .inventory-table {
    max-width: fit-content;
    margin: 0 auto;
    table {
      thead {
        th {
          font-size: 14px;
          font-weight: 500;
          color: #838285;
        }
        background: transparent;
      }
      tbody {
        background: #fff;
        tr:not(:last-child) {
          border-bottom: solid 1px #cbd2d9;
        }
        td {
          font-size: 15px;
          font-weight: 700;
          height: 60px;
          padding: 20px;
          color: #59585b;
        }
      }
    }
  }
  .inventory_error {
    border: solid 1px $error-color;
  }
}
