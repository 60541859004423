.jc {
  .jobs_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .job {
    cursor: pointer;
    background: #fff;
    border: solid 2px transparent;
    border-radius: 5px;
    height: auto;
  }
  .selected_job_card {
    border: solid 2px #027cb5;
    box-shadow: 0px 10px 20px 0px #19b5fe1a;
    border-radius: 5px;
    background: #fffff6;
  }
  .card_content {
    .label {
      color: #838285;
      font-size: 15px;
      // font-weight: 400;
      font-weight: 200;
    }
    .value {
      color: #59585b;
      font-size: 15px;
      font-weight: 500;
    }
    .card_header {
      padding: 0 20px;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #cbd2d9;
    }
    .card_body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .card_footer {
      display: flex;
      align-items: center;
      gap: 50px;
      // justify-content: space-between;
    }
  }
  .job_id {
    color: #37372d;
    font-size: 16px;
    font-weight: 500;
  }
  .new_job_label {
    color: #ffffff;
    background: #027cb5;
    text-align: center;
    padding: 0px 10px;
    border-radius: 50px;
    font-size: 14px;
  }
  .job_status {
    display: flex;
    font-size: 14px;
    // padding: 0px 10px;
    border-radius: 50px;
    // color: #ffffff;
    // text-transform: capitalize;
  }
  // .job_status.INITIATED {
  //   background: #37372d;
  //   color: #f5f7f7;
  // }
  // .job_status.ASSIGNED {
  //   background: green;
  // }
  .src-dest {
    height: 100px;
    .src,
    .dest {
      min-height: 48px;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #59585b;
    }
    .dest {
      position: relative;
      top: -33px;
    }
    .route {
      position: relative;
      top: -33px;
      margin-left: 7px;
      height: 40px;
      border-left: solid 1px #cbd2d9;
    }
    .source_icon {
      height: 15px;
      min-width: 15px;
      color: #cbd2d9;
    }
    .source_name {
      position: relative;
      top: -5px;
    }
    .destination_icon {
      height: 15px;
      min-width: 15px;
      color: #19b5fe;
    }
    .destination_name {
      position: relative;
      top: -5px;
    }
  }
}
