@import "./../variables";

.transporter-page {
  padding: 0 20px;
  height: calc(100vh - 259px);
  overflow: auto;

  .table-container {
    overflow-x: auto;
    scrollbar-width: none;
    max-width: 100%;
    position: relative;
    height: 100%;
  }

  table {
    thead {
      th {
        height: 50px;
        position: sticky;
        top: 0px;
        background: white;
        font-size: 14px;
        font-weight: 400;
        color: #838285;
      }
      background: transparent;

      .fixed-col {
        background-color: #fff;
        z-index: 3;
      }
    }
    tbody {
      background: #fff;
      tr:not(:last-child) {
        border-bottom: solid 1px #cbd2d9;
      }
      td {
        font-size: 15px;
        font-weight: 500;
        height: 60px;
        color: #59585b;
      }
      td:last-child {
        padding-right: 10px;
        width: 100%;
      }
      .transporter-name {
        min-width: 50%;
      }
      .container-count {
        width: 160px;
      }
      input {
        height: 40px;
      }
    }
  }

  .cb-area-col {
    width: 60px;
    min-width: 60px;
  }

  .sl-no-col {
    width: 50px;
    min-width: 50px;
  }

  .transporter-col {
    min-width: 167px;
  }

  .container-size-col {
    text-align: left;
  }

  .fixed-col {
    position: sticky;
    background-color: inherit;
  }

  .fixed-col-1 {
    left: 0;
  }

  .fixed-col-2 {
    left: 60px;
  }

  .fixed-col-3 {
    left: 110px;
  }

  .excess_error {
    color: $error-color;
  }

  .perfect {
    color: $success-color;
  }

  .shortage_error {
    color: $warning-color;
  }
}
