@media (max-height: 800px) {
  .job-details-page {
    height: calc(100vh - 210px);
  }
}

.job-details-page {
  border-radius: 10px;
  overflow: auto;
  .label {
    min-width: 110px;
  }
  .label {
    color: #838285;
    font-size: 15px;
    font-weight: 200;
  }
  .value {
    color: #59585b;
    font-size: 15px;
    font-weight: 500;
  }
  .details_holder {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
    .row {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .inventory_details {
    display: flex;
    .value {
      gap: 20px;
      display: flex;
    }
  }

  .trip_estimations {
    margin-top: 20px;
    .section {
      padding: 20px 0;
      width: 50%;
      display: inline-block;
    }
    .left,
    .right {
      align-items: center;
      display: flex;
      gap: 30px;
    }
    border-radius: 8px;
    background: #19b5fe33;
    padding: 0 20px;

    .label {
      min-width: unset;
    }
    .label,
    .value {
      color: #027cb5;
    }
  }
  .map_style {
    // border: solid 1px #cbd2da;
    border-radius: 10px;
    min-height: calc(100vh - 630px) !important;
    margin-top: 20px;
  }
}
