.modal-component {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    // backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .modal-header {
    padding: 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-name {
      font-size: 20px;
      font-weight: 700;
    }
    .header-buttons {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .modal-body {
    height: 100%;
    width: 100%;
    padding: 0 20px 20px;
    overflow: auto;
    scrollbar-width: none;
  }
}
