@import "./../../variables";

.forgot-password-page {
  .card-container {
    padding: 80px;
    min-width: 560px;
    border-radius: 10px;
    background: #fff;
  }

  .header-cover {
    padding: 0px 0px 20px 0px;
  }

  .primary_button {
    width: 100%;
  }

  .email_sent_form {
    width: 800px;
    .success_email_banner {
      max-width: 375.946px;
      max-height: 375.946px;
      margin: auto auto 20px auto;
    }
    .success_msg {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 20px;
    }
  }
}
