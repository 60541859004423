@import "./variables";

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// common (application wide) styles only

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $icon-dark;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $text-color;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.ambient-bkg {
  background: url("/assets/images/ambient-gradient-new.jpeg") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

.header-text {
  font-size: 28px;
  font-weight: 500;
  color: #37372d;
}

.sub-header-text {
  font-size: 18px;
  font-weight: 400;
  color: #838285;
}

label {
  color: $label-color;
  font-size: 14px;
  font-weight: 200;
  // font-weight: 400;
  margin-bottom: 6px;
}

.required_field_label:after {
  content: " *";
  color: $error-color;
}

.primary_button {
  border-radius: 5px;
  background-color: $primary-color;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 40px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-style: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.primary_button:hover:not([disabled]),
.primary_button:focus:not([disabled]) {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.primary_button:hover:not([disabled]) {
  transform: translateY(-1px);
}

.primary_button:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.primary_button:disabled {
  background-color: $disabled-color;
  cursor: not-allowed;
  color: $text-color;
}

.primary_button_short {
  @extend .primary_button;
  min-width: 160px;
}

.danger_button {
  @extend .primary_button;
  background-color: $error-color;
}

.secondary_button {
  @extend .primary_button;
  color: $text-color;
  background-color: #fff;
  border: 1px solid $border-color;
}

.tertiary_button {
  @extend .primary_button;
  color: $primary-color;
  background-color: #fff;
  border: 1px solid $primary-color;
}

.tertiary_button:disabled {
  border-color: transparent;
}

input {
  // margin: 0 0 20px 0;
  color: $text-color;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  padding: 0px 15px 0px 15px;
  gap: 0px;
  border-radius: 3px;
  border: 1px solid $border-color;
  &::placeholder {
    font-weight: 500 !important;
    color: $border-color;
    user-select: none;
  }
  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: $border-color;
  }
  &:focus-visible {
    // border-color: transparent;
    outline: 1px solid transparent;
  }
}

input[type="file"] {
  margin: auto;
  padding: 1.5em;
  border: 2px dashed $border-color;
  background-color: #fff;
  transition: border-color 0.25s ease-in-out;
  &::file-selector-button {
    padding: 1em 1.5em;
    border-width: 0;
    border-radius: 2em;
    background-color: $primary-color;
    color: #fff;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    margin-right: 1em;
  }
  &:hover {
    border-color: $primary-color;
    &::file-selector-button {
      background-color: $primary-color;
    }
  }
}

.form-container,
form {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
  .form-field {
    display: contents;
  }
  display: flex;
  flex-direction: column;
  .validation_error {
    color: $error-color;
    font-size: 13px;
    margin-top: 3px;
  }
  .invalid_field {
    border: solid 1px $error-color;
    &:focus-visible {
      outline: transparent;
    }
    svg {
      color: $error-color;
    }
  }
}

.no-mb {
  margin-bottom: 0;
}

input.form_error {
  border-color: $error-color;
  outline: 1px solid transparent;
  // background-color: #e3003408;
}

.form_validation_error {
  color: $error-color;
  position: relative;
  top: -22px;
}

.error_color {
  color: $error-color !important;
}

a {
  font-weight: 500;
  font-size: 14px;
  line-height: 18.23px;
  color: $primary-color;
  text-decoration: underline;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #19b5fe7d;
  }
}

.link-style {
  font-weight: 500;
  font-size: 14px;
  line-height: 18.23px;
  color: $primary-color;
  text-decoration: underline;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
}

.text_align_center {
  text-align: center;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: $primary-color !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: $success-color !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: $warning-color !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: $error-color !important;
}

.page-header {
  border-bottom: solid 1px #cbd2d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 20px 10px;
  box-shadow: 10px 4px 20px 0px #041d290d;
  .header-name {
    font-size: 18px;
    font-weight: 500;
  }
}

.card-shadow {
  box-shadow: 0px 4px 4px 0px #0000000d;
}

/* Style the autocomplete dropdown container */
.pac-container {
  margin-top: 1px;
  background-color: white; /* Change background color */
  border-radius: 4px; /* Add rounded corners */
  border: solid 1px #cbd2d9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
  font-size: 14px; /* Change font size */
  z-index: 1000; /* Ensure the dropdown appears above other elements */
}

.pac-container:after {
  content: none !important;
}

/* Style individual autocomplete items */
.pac-item {
  padding: 8px 15px; /* Add padding */
  cursor: pointer;
  font-weight: normal; /* Adjust font weight */
  .pac-icon {
    display: none;
  }
}

/* Highlight on hover */
.pac-item:hover {
  background-color: $background-color; /* Light gray hover effect */
}

/* Style the highlighted part of the input text in the dropdown */
.pac-item-query {
  font-weight: bold; /* Bold text for matched query */
  color: $text-color; /* Change text color */
  span {
    color: $primary-color;
  }
}

/* Remove the "powered by Google" logo */
.pac-container:after {
  display: none !important; /* Hides the logo */
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"]
{
  display: none !important;
}
.gm-bundled-control .gmnoprint {
  display: block;
}
.gmnoprint:not(.gm-bundled-control) {
  display: none;
}

.hidden {
  display: none;
}
.visible {
  display: block;
}

.default_border {
  border: solid 1px $border-color;
}

.main_area_inset_shadow {
  background: $background-color;
  box-shadow: inset 10px 4px 20px 0px #041d290d;
}

.truncate-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
  filter: grayscale(1);
}

.center_floater_container {
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  .no_data_wrapper {
    max-width: 550px;
    text-align: center;
    .msg {
      color: #59585b;
      font-weight: 400;
      font-size: 16px;
      margin-top: 20px;
    }
  }
}

.track_button {
  @extend .primary_button;
  background-color: #027cb5;
  // border-color: none;
  border: 1px solid transparent;
  color: #fffff6;
  height: 40px;
  padding: 0 4px;
  .button_inner_container {
    display: flex;
    align-items: center;
    svg {
      width: 14px;
      margin-left: 6px;
    }
    .truckNo {
      font-size: 14px;
      padding: 0 6px;
    }
  }
}

.track_button:disabled {
  border: none;
  .button_inner_container {
    // justify-content: center;
    width: 100%;
    font-weight: 300;
  }
}

.track_button.dropped_status {
  .button_inner_container {
    font-weight: 400;
  }
  background-color: #01ba650f;
  color: $success-color;
  svg {
    width: 16px;
    margin-left: 5px;
  }
  // border: solid 1px $success-color;
}

.excel_import_button {
  @extend .primary_button;
  background-color: #20744a;
}

.excel_download_button {
  @extend .primary_button;
  color: #20744a;
  border: 1px solid #20744a;
  background: #fff;
}

// .track_button.picked_status {
//   background-color: #027cb54d;
//   color: #027cb5;
// }

// border: solid 2px ;
//     box-shadow: 0px 10px 20px 0px rgba(25, 181, 254, 0.1019607843);
//     border-radius: 5px;
//     background: #fffff6;

.component_modal_container {
  width: fit-content !important;
  height: fit-content !important;
}

.switched_on {
  .MuiFormControlLabel-label {
    color: #8cdafe !important;
  }
}
