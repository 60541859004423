.trip-history-page {
  height: calc(100vh - 70px);
  overflow: auto;
  .job_preview_modal {
    width: 50%;
    .modal-body {
      max-height: 90%;
      .card-shadow {
        box-shadow: none;
      }
    }
  }
}
