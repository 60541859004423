.profile-page {
  height: calc(100vh - 70px);
  justify-content: center;
  width: 100%;
  background-color: #eaebed;
  box-sizing: border-box;
}

.profile-card {
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0;

  .card-top {
    position: relative;
    width: 100%;
    height: 150px;
    margin: 0;
    padding: 0;
    border-radius: 10px 10px 0 0;

    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .avatar {
      position: absolute;
      bottom: -50px;
      left: 20px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;

      .avatar-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .containerbox {
    margin-top: 60px;
    margin-left: 20px;
  }

  .container {
    margin-top: 20px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
    max-width: 98%;

    .header-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 16px;
        font-weight: bold;

        color: #505047;
      }

      .edit-button {
        padding: 6px 16px;
        font-weight: bold;
        color: #59585c;
        border: 1px solid #cbd2d9;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background-color: #4f89c2;
          color: #fff;
        }
      }
    }

    .flex-row {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      align-items: flex-start;

      .flex-col {
        display: flex;
        flex-direction: column;

        .label {
          font-size: 13px;
          color: #b1b0b3;
        }

        .value {
          font-size: 15px;
          font-weight: bold;
          color: #59585c;
          white-space: nowrap;
        }
      }

      .half-width {
        flex: 0 0 20%;
        box-sizing: border-box;
      }

      .third-width {
        flex: 0 0 20%;
        box-sizing: border-box;
      }

      .full-width {
        flex: 1;
      }
    }
  }
}
