.signup-page {
  .card-container {
    padding: 80px;
    min-width: 960px;
    border-radius: 10px;
    background: #fff;
  }

  .header-cover {
    padding: 40px 0px 20px 0px;
  }

  .primary_button {
    width: 100%;
  }

  .email_login_options {
    margin-bottom: 10px;
    min-height: 36px;
  }

  .form-field-cover {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
  }

  .footer_msg {
    padding-top: 30px;
    color: #838285;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
}
