.job-preview {
  display: flex;
  flex-direction: column;
  .main {
    padding: 20px 20px 0;
    .row {
      display: flex;
      align-items: flex-start;
      .label {
        color: #838285;
        font-size: 14px;
        font-weight: 200;
        min-width: 137px;
      }
      .value {
        text-transform: uppercase;
        color: #59585b;
        font-size: 15px;
        font-weight: 500;
      }
      margin-bottom: 16px;
    }
  }
  .cycle_time {
    display: flex;
    align-items: center;
    background: #f5f7f7;
    padding: 10px 20px;
    .label {
      color: #37372d;
      font-size: 16px;
      font-weight: 600;
      min-width: 137px;
    }
    .value {
      color: #37372d;
      font-size: 16px;
      font-weight: 900;
    }
  }
}
