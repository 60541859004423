.container-details-component {
  padding: 20px;
  .stats {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #f3f4f6;
    border-radius: 10px;
    // border: solid 1px #cbd2da;
    .stat-block {
      padding: 20px;
      &:not(:last-child) {
        border-right: solid 1px #cbd2da7d;
      }
    }
    .label {
      color: #838285;
      font-size: 16px;
      font-weight: 500;
    }
    .value {
      // text-align: center;
      color: #59585b;
      font-size: 32px;
      font-weight: 700;
    }
    .total_value {
      color: #59585b;
      // color: #027cb5;
    }
    .assigned_value {
      // color: #01ba65;
      color: #027cb5;
    }
    .pending_value {
      color: #e30034;
    }
  }
  .table-container {
    // background: #f5f7f7;
    border: solid 1px #cbd2d9;
    border-radius: 10px;
    padding: 0 20px;
    // max-height: calc(104vh - 450px);
    overflow: auto;
    scrollbar-width: none;
    table {
      thead {
        z-index: 1;
        position: sticky;
        top: 0;
        th {
          padding-top: 20px;
          background: #fff;
          font-size: 14px;
          font-weight: 200;
          color: #838285;
          text-align: left;
        }
      }
      tbody {
        tr:not(:last-child) {
          border-bottom: solid 1px #cbd2d9;
        }
        td {
          font-size: 15px;
          font-weight: 500;
          height: 60px;
          color: #59585b;
        }
        td:last-child {
          width: 1%;
          white-space: nowrap;
          padding-right: 10px;
          // width: 100%;
        }
        input {
          height: 40px;
        }
      }
      .sl_no {
        text-align: center;
      }
      // .container_no {
      //   input {
      //     width: 100%;
      //   }
      //   min-width: 140px;
      // }
      // .sity {
      //   min-width: 100px;
      // }
      // .transporter_name {
      //   min-width: 170px;
      // }
    }
  }

  .map_with_path {
    width: 100%;
    min-width: 800px;
    height: calc(100vh - 300px);
  }

  .live-tracking-modal {
    .modal-body {
      height: calc(100vh - 170px);
    }
  }
}
