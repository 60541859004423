.live-tracking-component {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .src-dest {
    height: 74px;
    .src,
    .dest {
      min-height: 48px;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #59585b;
    }
    .dest {
      position: relative;
      top: -33px;
    }
    .route {
      position: relative;
      top: -33px;
      margin-left: 7px;
      height: 40px;
      border-left: solid 1px #cbd2d9;
    }
    .source_icon {
      height: 15px;
      min-width: 15px;
      color: #cbd2d9;
    }
    .source_name {
      position: relative;
      top: -5px;
    }
    .destination_icon {
      height: 15px;
      min-width: 15px;
      color: #19b5fe;
    }
    .destination_name {
      position: relative;
      top: -5px;
    }
  }

  .live-stats {
    border: solid 1px #cbd2d9;
    width: 450px;
    height: 100%;
    overflow: hidden;
    margin-left: 20px;
    border-radius: 10px;
    .title {
      padding: 20px;
      border-bottom: solid 1px #cbd2d9;
      .job_id {
        color: #37372d;
        font-weight: 500;
        font-size: 16px;
      }
    }
    .src_dest_cover {
      padding: 20px;
      border-bottom: solid 1px #cbd2d9;
    }
    .job_details {
      .status_title {
        padding-bottom: 6px;
      }
      padding: 10px 20px 23px;
      border-bottom: solid 1px #cbd2d9;
      .total_dropped_containers {
        color: #01ba65;
      }
      .total_containers {
        color: #59585b;
      }
      .job_progress_cover {
        border-radius: 50px;
        overflow: hidden;
        border: solid 1px #cbd2d9;
        .job_progress {
          background: #01ba65;
          border-radius: 1px;
          // border-radius: 0 6px 10px 0;
        }
      }
    }
    .truck_list {
      max-height: calc(100vh - 451px);
      padding: 20px;
      overflow: auto;
      scrollbar-width: none;
      .truck_card {
        border-radius: 8px;
        border: solid 1px #cbd2d9;
        .header {
          padding: 15px 20px;
          border-bottom: solid 1px #cbd2d9;
        }
        .truck_no {
          font-weight: 500;
          color: #37372d;
        }
        .container_details {
          padding: 15px 20px;
          .label {
            color: #838285;
            font-size: 13px;
            font-weight: 300px;
          }
          .value {
            color: #59585b;
            font-size: 14px;
            font-weight: 800px;
          }
        }
      }
      .selected_truck {
        border-color: #19b5fe;
        background: #fffff6;
      }
      .tracking-style {
        display: flex;
        align-items: center;
        color: #01ba65;
        gap: 5px;
        font-size: 12px;
      }
    }
  }

  .map_style {
    height: calc(100vh - 187px);
    border: solid 1px #cbd2d9;
    border-radius: 10px;
  }

  .custom-info-window {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 250px;
  }

  .custom-info-window .header {
    color: #19b5fe;
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #cbd2d9;
    padding-bottom: 5px;
  }

  .custom-info-window .content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    color: #59585b;
  }
}
