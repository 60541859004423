@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./variables";

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%; /* Ensures that the body and html elements take up full height */
  overflow: hidden;
  font-family: $font-family-base !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
