.job-breakup {
  background: #fff;
  .breakup-table-container {
    border-radius: 10px;
    padding: 0 20px;
    max-height: calc(100vh - 266px);
    overflow-y: auto;
    table {
      thead {
        th {
          height: 50px;
          position: sticky;
          top: 0px;
          background: #fff;
          font-size: 14px;
          font-weight: 200;
          color: #838285;
          text-align: left;
        }
        background: transparent;

        .fixed-col {
          background-color: #fff;
          z-index: 3;
        }
      }
      tbody {
        td {
          background: #f5f7f7;
          font-size: 15px;
          font-weight: 500;
          height: 60px;
          color: #59585b;
        }
        td:last-child {
          padding-right: 10px;
          width: 100%;
        }
        input {
          height: 40px;
        }
      }
      .sl_no {
        min-width: 50px;
        text-align: center;
      }
      .container_no {
        input {
          width: 100%;
        }
        min-width: 233px;
      }
      .sity {
        min-width: 100px;
        text-align: center;
      }
    }
    .validation_error {
      color: #e30034;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
