.page-header {
  left: -1px;
  border-left: solid 1px #fff;
  position: relative;
  .left-section {
    min-width: 235px;
  }
  .center-section {
    .search-bar-component {
      min-width: 472px;
    }
  }
}
