.transporter-details-component {
  padding: 20px;
  height: calc(100vh - 227px);
  .transporter_card {
    // border: solid 1px #cbd2da;
    background: #f5f7f7;
    border-radius: 10px;
    .header {
      padding: 15px 20px;
      font-weight: 500;
      font-size: 16px;
      color: #37372d;
      border-bottom: solid 1px #cbd2d9;
    }

    .trailer_category {
      .left {
        display: flex;
        align-items: center;
        color: #838285;
        font-size: 16px;
        font-weight: 200;
      }
      .right {
        color: #59585b;
        font-weight: 500;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      .completed_trips {
        color: #01ba65;
      }
    }
  }
  .icon_style {
    min-width: 35px;
    margin-right: 10px;
  }

  .table-container {
    // overflow-x: auto;
    // max-width: 100%;
    // position: relative;
    // height: 100%;
    width: 100%;
    // background: red;
  }

  table {
    thead {
      th {
        height: 50px;
        background: white;
        font-size: 14px;
        font-weight: 500;
        color: #838285;
      }
      background: transparent;
    }
    tbody {
      background: #fff;
      // tr:not(:last-child) {
      //   border-bottom: solid 1px #cbd2d9;
      // }
      td {
        font-size: 15px;
        font-weight: 700;
        height: 60px;
        color: #59585b;
      }
      td:last-child {
        padding-right: 10px;
        width: 100%;
      }
      input {
        height: 40px;
      }
    }
  }

  .sl-no-col {
    width: 50px;
    min-width: 50px;
  }
}
