@import "./../../variables";

.search-bar-component {
  display: flex;
  align-items: center;
  input {
    padding: 10px 15px;
    margin: 0;
    width: 100%;
    min-width: 250px;
    border-radius: 5px 0 0 5px;
    &:focus-visible {
      border-color: $primary-color;
      outline: none;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f7f7;
    height: 40px;
    width: 47px;
    margin: 0;
    padding: 0;
    border-radius: 0 5px 5px 0;
    border: 1px solid $border-color;
    border-left: none;
    svg {
      color: #a3a8b4;
    }
  }
  .focused-btn {
    border-color: $primary-color;
    background: #d1f0ff;
    svg {
      color: $primary-color;
    }
  }
}
