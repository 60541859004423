@import "./../../variables";

.sidebar_style {
  user-select: none;
  position: relative;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px 10px 10px;
  border-right: solid 1px #cbd2d9;
  transition: width 0.3s ease, transform 0.3s ease;
  background-color: #ffffff;

  &.overlay {
    position: absolute;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .sidebar_items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 114px);
  }

  .sidebar_parent_button {
    cursor: pointer;
    &:hover {
      background-color: #f6f7f773;
    }
  }

  .sidebar_parent {
    a,
    .sidebar_parent_button {
      padding: 10px 15px;
      height: 60px;
      width: 100%;
      text-decoration: none;
      color: #59585b;
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
      border-radius: 5px;
      transition: background-color 0.3s, color 0.3s;
      gap: 10px;
    }

    .icon {
      font-size: 1.2em;
    }

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .active {
      font-weight: 700;
      background-color: #f5f7f7 !important;
      color: #37372d;
      .text {
        color: #37372d;
      }
      .icon {
        color: #37372d;
      }
    }

    a:hover {
      background-color: #f6f7f773;
    }

    .opened_menu {
      background-color: #f6f7f773;
      // background-color: #80808012;
      a {
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .sub_menu {
    border-radius: 0 0 5px 5px;
  }

  .sub_menu.active {
    background-color: #f5f7f7;
  }

  .sidebar_child {
    a {
      display: block;
      padding: 10px 0;
      color: #59585b;
      padding-left: 52px;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      font-weight: 500;
    }
    a:hover {
      color: #37372d;
      // background-color: #f6f7f773;
      background-color: #80808012;
    }
    a.active {
      color: #19b5fe;
      font-weight: 600;
    }
  }

  .divider {
    border-bottom: solid 1px $border-color;
    margin: 10px 0;
  }

  .icon {
    color: #a3a8b4;
    min-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  ul li {
    &.accordion_toggle {
      cursor: pointer;
      margin-left: auto;
      margin-right: 5px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .logout_btn {
    user-select: none;
    display: flex;
    align-items: center;
    padding-left: 14px;
    height: 60px;
    color: $error-color;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: left;
    padding-left: 14px;
    border-radius: 5px;

    .icon {
      color: $error-color;
      min-width: 26px;
      margin-left: 4px;
      margin-right: 9px;
    }

    &:hover {
      background-color: #f6f7f7;
    }
  }

  .toggle_sidebar_btn {
    position: absolute;
    top: 40px;
    right: 25px;
    color: #a3a8b4;
    cursor: pointer;
    &:hover {
      color: #19b5fe;
    }
  }

  .logo {
    transition: width 0.3s ease, opacity 0.3s;
    min-height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    // &.pinned_logo {
    //   width: 240px;
    // }

    &.collapsed_logo {
      width: 64px;
      opacity: 0.7;
    }
  }

  .accordion_toggle {
    cursor: pointer;
    margin-left: auto;
    margin-right: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #a3a8b4;
    background: transparent;
    border: none;
    outline: none;
    transition: transform 0.3s;

    &.expanded {
      transform: rotate(-180deg);
    }
  }
}
