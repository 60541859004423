@import "./../../variables";
.coming-soon-page {
  background: $background-color;
  .card-container {
    padding: 80px;
    min-width: 560px;
    border-radius: 10px;
    background: #fff;
  }
  .heading {
    .sub_header {
      padding-top: 10px;
      font-weight: 300;
      color: $text-color;
      max-width: 650px;
    }
  }
}
