.existing-job-page {
  height: calc(100vh - 71px);

  #jobs-list {
    overflow-y: auto;
    scrollbar-width: none;
  }

  .details_panel {
    height: calc(100vh - 108px);
    overflow: hidden;
  }

  .container-details-component {
    height: calc(100vh - 211px);
    overflow-y: scroll;
  }

  .assign_containers_modal {
    min-width: 660px;
  }

  .import_containers_modal {
    min-width: 500px;
    .upload_area {
      input {
        width: 100%;
        height: 100px;
      }
      input::file-selector-button {
        background-color: #20744a;
        color: #fff;
      }
      input:hover {
        border-color: #20744a;
      }
    }
    .download_area {
      .row {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      svg {
        max-width: 35px;
      }
    }
  }

  .jobs-list,
  .selected-job-details {
    width: 100%;
  }
}
