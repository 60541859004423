@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Colors
$primary-color: #19b5fe;
$background-color: #f5f7f7;
$border-color: #cbd2d9;
$label-color: #838285;
$icon-light: #d0d3d9;
$icon-dark: #a3a8b4;
$heading-color: #37372d;
$text-color: #59585b;
$disabled-color: #eef0f2;
$success-color: #01ba65;
$warning-color: #d6a501;
$error-color: #ff0000;

// Fonts
$font-family-base: "DM Sans", sans-serif;
$font-size-base: 16px;

// Spacing
$spacing-unit: 8px;
