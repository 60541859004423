.new-job-page {
  .form-cover {
    display: flex;
    align-items: flex-start;
  }

  .left-panel {
    // min-width: 360px;
    width: 360px;
    height: calc(100vh - 101px);
    overflow: auto;
    scrollbar-width: none;
    padding-bottom: 4px;
  }

  .right-panel {
    // max-width: calc(100vw - 700px);
    width: 100%;
    position: relative;
    height: calc(100vh - 105px);
    overflow: hidden;
  }

  .action-button-bar {
    background: #fff;
    padding: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  #modal-map {
    min-height: calc(100vh - 375px);
    height: 100%;
  }
}
